import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import QuizModal from "../modals/QuizModal"

type TermOfServiceProps = {
  modal?: boolean
  onModal?: (modal: boolean) => void
}

const TermOfService: React.FC<TermOfServiceProps> = ({ modal, onModal }) => {
  const navigate = useNavigate()

  const { pathname } = useLocation()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  const [quiz, setQuiz] = useState(false)

  const onClose = useCallback((quiz: boolean) => {
    setQuiz(!quiz)
  }, [])

  const onShow = useCallback(
    (quiz: boolean) => {
      if (onModal) {
        onModal(quiz)
        setQuiz(quiz)
      } else {
        onClose(quiz)
      }
      setQuiz(quiz)
    },
    [onClose, onModal]
  )

  return (
    <section className="section privacy_section" style={{ paddingTop: 184 }}>
      <QuizModal quiz={modal === true ? modal : quiz} onClose={onShow} key={modal || quiz ? 'opened' : 'closed'} />
      <div className="container container-small">
        <article>
          <div className="article_header">
            <img src="assets/images/icons/privacy-icon.svg" alt="privacy" />
          </div>
          <h4>User Agreement</h4>
          <div className="article_meta">Last updated: August 23 2024</div>
          <p>
            Please read this End­User License Agreement ("Agreement") carefully
            before clicking the "I Agree" button, downloading or using Yamfit
            ("Application"). By clicking the "I Agree" button, downloading or
            using the Application, you are agreeing to be bound by the terms and
            conditions of this Agreement. If you do not agree to the terms of
            this Agreement, do not click on the "I Agree" button and do not
            download or use the Application.
          </p>

          <h5>License</h5>
          <p>
            Yamfit grants you a revocable, non­exclusive, non­transferable,
            limited license to download, install and use the Application solely
            for your personal, non­ commercial purposes strictly in accordance
            with the terms of this Agreement.
          </p>
          <h5>Restrictions</h5>
          <p>
            You agree not to, and you will not permit others to: a) license,
            sell, rent, lease, assign, distribute, transmit, host, outsource,
            disclose or otherwise commercially exploit the Application or make
            the Application available to any third party. The Restrictions
            section is for applying certain restrictions on the app usage, e.g.
            user can't sell app, user can't distribute the app. For the full
            disclosure section, create your own EULA.
          </p>
          <h5>Modifications to Application</h5>
          <p>
            Yamfit reserves the right to modify, suspend or discontinue,
            temporarily or permanently, the Application or any service to which
            it connects, with or without notice and without liability to you.
            The Modifications to Application section is for apps that will be
            updated or regularly maintained. For the full disclosure section,
            create your own EULA.
          </p>
          <h5>Term and Termination</h5>
          <p>
            This Agreement shall remain in effect until terminated by you or
            Yamfit. Yamfit may, in its sole discretion, at any time and for any
            or no reason, suspend or terminate this Agreement with or without
            prior notice. This Agreement will terminate immediately, without
            prior notice from Yamfit, in the event that you fail to comply with
            any provision of this Agreement. You may also terminate this
            Agreement by deleting the Application and all copies thereof from
            your mobile device or from your desktop. Upon termination of this
            Agreement, you shall cease all use of the Application and delete all
            copies of the Application from your mobile device or from your
            desktop.
          </p>
          <h5>Severability</h5>
          <p>
            If any provision of this Agreement is held to be unenforceable or
            invalid, such provision will be changed and interpreted to
            accomplish the objectives of such provision to the greatest extent
            possible under applicable law and the remaining provisions will
            continue in full force and effect.
          </p>
          <h5>Amendments to this Agreement</h5>
          <p>
            Yamfit reserves the right, at its sole discretion, to modify or
            replace this Agreement at any time. If a revision is material we
            will provide at least 30 (changes this) days' notice prior to any
            new terms taking effect. What constitutes a material change will be
            determined at our sole discretion.
          </p>
          <h5>Contact Information</h5>
          <p>
            If you have any questions about this Agreement, please contact us.
          </p>

          <div className="article_action">
            <span
              onClick={() => navigate("/")}
              className="btn-green-transparent"
            >
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g filter="url(#filter0_i_1_6562)">
                  <mask
                    id="mask0_1_6562"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="3"
                    y="3"
                    width="12"
                    height="12"
                  >
                    <path
                      d="M14.0935 8.24997H5.71601L9.37601 4.58997C9.66851 4.29747 9.66851 3.81747 9.37601 3.52497C9.08351 3.23247 8.61101 3.23247 8.31851 3.52497L3.37601 8.46747C3.08351 8.75997 3.08351 9.23247 3.37601 9.52497L8.31101 14.475C8.45113 14.6154 8.64137 14.6943 8.83976 14.6943C9.03814 14.6943 9.22838 14.6154 9.36851 14.475C9.66101 14.1825 9.66101 13.71 9.36851 13.4175L5.71601 9.74997H14.0935C14.506 9.74997 14.8435 9.41247 14.8435 8.99997C14.8435 8.58747 14.506 8.24997 14.0935 8.24997Z"
                      fill="black"
                    />
                  </mask>
                  <g mask="url(#mask0_1_6562)">
                    <rect
                      x="18"
                      y="18"
                      width="18"
                      height="18"
                      transform="rotate(-180 18 18)"
                      fill="#51CC47"
                    />
                  </g>
                </g>
                <defs>
                  <filter
                    id="filter0_i_1_6562"
                    x="0"
                    y="0"
                    width="18"
                    height="22"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                  >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend
                      mode="normal"
                      in="SourceGraphic"
                      in2="BackgroundImageFix"
                      result="shape"
                    />
                    <feColorMatrix
                      in="SourceAlpha"
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                      result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite
                      in2="hardAlpha"
                      operator="arithmetic"
                      k2="-1"
                      k3="1"
                    />
                    <feColorMatrix
                      type="matrix"
                      values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                      mode="normal"
                      in2="shape"
                      result="effect1_innerShadow_1_6562"
                    />
                  </filter>
                </defs>
              </svg>
              Back to Home Page
            </span>
          </div>
        </article>
      </div>
    </section>
  )
}
export default TermOfService
