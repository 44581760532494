import React, { useState } from "react";
import TestimonialsSlider from "./TestimonialsSlider";
import { TestimonialItems } from "../constants";
import TestimonialItem from "./TestimonialItem";

const Testimonials: React.FC = () => {
  const [isMobile] = useState(window.innerWidth < 768);
  return (
    <section
      className="section bg3 section_testimonials"
      style={{ marginTop: -1 }}
    >
      <div className="container">
        <div className="system_slider">
          {isMobile ? (
            <TestimonialsSlider />
          ) : (
            <div className="slick-track" style={{ gap: 24 }}>
              {TestimonialItems.map((item, index) => {
                return (
                  <TestimonialItem
                    key={index}
                    title={item.title}
                    description={item.description}
                    userName={item.userName}
                    date={item.date}
                  />
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};
export default Testimonials;
