import React, { useCallback, useEffect, useRef, useState } from "react"
import Slider from "react-slick"

import QuizModal from "../modals/QuizModal"

const ProductSection: React.FC = () => {
  const [isMobile] = useState(window.innerWidth < 768)

  const [nav1, setNav1] = useState()
  const [nav2, setNav2] = useState()
  const [nav3, setNav3] = useState()
  const slider1 = useRef(null)
  const slider2 = useRef(null)
  const slider3 = useRef(null)

  useEffect(() => {
    setNav1(slider1.current as any)
    setNav2(slider2.current as any)
    setNav3(slider3.current as any)
  }, [])

  const SlickButtonFix = (props: {
    children: JSX.Element
    slideCount?: number
    currentSlide?: number
  }) => {
    const { children, currentSlide, slideCount, ...others } = props
    return <span {...others}>{children}</span>
  }

  const [quiz, setQuiz] = useState(false)

  const onClose = useCallback((quiz: boolean) => {
    setQuiz(quiz)
  }, [])

  return (
    <section
      className="section bg2 product_section"
      id="mobile_product"
      style={{ marginTop: -1, overflow: "hidden" }}
    >
      <div
        className="section_bg opacity-1"
        style={{ backgroundImage: "url(assets/images/bg-3.webp)" }}
      ></div>
      <div className="container container_box product_container">
        <div className="product_slider_top">
          <h3>Part of a complete system</h3>
          {!isMobile ? (
            <Slider
              className="slider-nav"
              asNavFor={nav1}
              ref={slider3}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              infinite={true}
              speed={500}
              slidesToScroll={1}
              nextArrow={
                <SlickButtonFix>
                  <button
                    type="button"
                    className="slick-next slick-arrow"
                    style={{ display: "block" }}
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.17383 14.0508H16.8203L10.9854 19.8857L12.499 21.3262L20.8242 13.001L12.499 4.67578L11.0342 6.11621L16.8203 11.9512H4.17383V14.0508Z"
                        fill="#5A6270"
                      ></path>
                    </svg>
                  </button>
                </SlickButtonFix>
              }
              prevArrow={
                <SlickButtonFix>
                  <button
                    type="button"
                    className="slick-prev slick-arrow"
                    style={{ display: "block" }}
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.8262 11.9492H8.17969L14.0146 6.11426L12.501 4.67383L4.17578 12.999L12.501 21.3242L13.9658 19.8838L8.17969 14.0488H20.8262V11.9492Z"
                        fill="#5A6270"
                      ></path>
                    </svg>
                  </button>
                </SlickButtonFix>
              }
            >
              {/*  <!-- slide 1 thumb --> */}
              <div className="slide_thumb">
                <div className="slide_thumb_img">
                  <img
                    src="assets/images/product-slider/camera-1.png"
                    alt="slide"
                  />
                </div>
                <div className="slide_thumb_txt">Security Cameras</div>
              </div>

              {/*  <!-- slide 2 thumb --> */}
              <div className="slide_thumb">
                <div className="slide_thumb_img">
                  <img
                    src="assets/images/product-slider/smart-phone.png"
                    alt="slide"
                  />
                </div>
                <div className="slide_thumb_txt">Window / Door Sensors</div>
              </div>

              {/*  <!-- slide 3 thumb --> */}
              <div className="slide_thumb">
                <div className="slide_thumb_img">
                  <img
                    src="assets/images/product-slider/smart-lock.png"
                    alt="slide"
                  />
                </div>
                <div className="slide_thumb_txt">Smart Locks</div>
              </div>

              {/*  <!-- slide 4 thumb --> */}
              <div className="slide_thumb">
                <div className="slide_thumb_img">
                  <img src="assets/images/product-slider/4.png" alt="slide" />
                </div>
                <div className="slide_thumb_txt">In/outdoor smart lights</div>
              </div>

              {/*  <!-- slide 5 thumb --> */}
              <div className="slide_thumb">
                <div className="slide_thumb_img">
                  <img src="assets/images/product-slider/5.png" alt="slide" />
                </div>
                <div className="slide_thumb_txt">Glass break sensors</div>
              </div>

              {/*  <!-- slide 6 thumb --> */}
              <div className="slide_thumb">
                <div className="slide_thumb_img">
                  <img
                    src="assets/images/product-slider/sensors.png"
                    alt="slide"
                  />
                </div>
                <div className="slide_thumb_txt">Gas/water leak detectors</div>
              </div>
            </Slider>
          ) : (
            <Slider
              className="slider-nav"
              asNavFor={nav1}
              ref={slider3}
              slidesToShow={3}
              swipeToSlide={true}
              focusOnSelect={true}
              infinite={true}
              speed={500}
              slidesToScroll={1}
              nextArrow={
                <SlickButtonFix>
                  <button
                    type="button"
                    className="slick-next slick-arrow"
                    style={{ display: "block" }}
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.17383 14.0508H16.8203L10.9854 19.8857L12.499 21.3262L20.8242 13.001L12.499 4.67578L11.0342 6.11621L16.8203 11.9512H4.17383V14.0508Z"
                        fill="#5A6270"
                      ></path>
                    </svg>
                  </button>
                </SlickButtonFix>
              }
              prevArrow={
                <SlickButtonFix>
                  <button
                    type="button"
                    className="slick-prev slick-arrow"
                    style={{ display: "block" }}
                  >
                    <svg
                      width="25"
                      height="26"
                      viewBox="0 0 25 26"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M20.8262 11.9492H8.17969L14.0146 6.11426L12.501 4.67383L4.17578 12.999L12.501 21.3242L13.9658 19.8838L8.17969 14.0488H20.8262V11.9492Z"
                        fill="#5A6270"
                      ></path>
                    </svg>
                  </button>
                </SlickButtonFix>
              }
            ></Slider>
          )}
        </div>
        <div className="middle-slider-wrapper">
          <Slider
            infinite={true}
            speed={500}
            slidesToScroll={1}
            slidesToShow={1}
            className="product_slider"
            asNavFor={nav2}
            ref={slider1}
            arrows={false}
            fade={true}
          >
            {/*  <!-- Slide Item 1 --> */}

            <div className="product_slider_item_image">
              <img src="assets/images/s-l16002 1.png" alt="slide img" />
            </div>

            {/*  <!-- Slide Item 2 --> */}
            <div className="product_slider_item_image">
              <img src="assets/images/product-slider/2_2.png" alt="slide img" />
            </div>

            {/*  <!-- Slide Item 3 --> */}
            <div className="product_slider_item_image">
              <img src="assets/images/product-slider/3_2.png" alt="slide img" />
            </div>

            {/*  <!-- Slide Item 4 --> */}
            <div className="product_slider_item_image">
              <img src="assets/images/product-slider/4_2.png" alt="slide img" />
            </div>

            {/*   <!-- Slide Item 5 --> */}
            <div className="product_slider_item_image">
              <img src="assets/images/product-slider/5_2.png" alt="slide img" />
            </div>

            {/*   <!-- Slide Item 6 --> */}
            <div className="product_slider_item_image">
              <img src="assets/images/product-slider/6_2.png" alt="slide img" />
            </div>
          </Slider>
          <div>
            <Slider
              infinite={true}
              speed={500}
              slidesToScroll={1}
              slidesToShow={1}
              className="title_slider height_auto"
              asNavFor={nav3}
              ref={slider2}
              arrows={false}
              fade={true}
            >
              <div className="product_slider_item_content">
                <h3
                  style={{ marginBottom: 10 }}
                  className="product_slider_title"
                >
                  Security Cameras
                </h3>
                <p className="product_slider_desc">
                  With Nuvogate™ AI, it's smarter exterior protection.
                </p>
                <ul className="nolist">
                  <li className="product_slider_desc">
                    Identifies and deters true threats
                  </li>
                  <li className="product_slider_desc">
                    Doesn't record crime, it helps prevent it
                  </li>
                  <li className="product_slider_desc">
                    Live and recorded video
                  </li>
                </ul>
                <div className="product_slider_item_content_action">
                  <span
                    className="button_white_transparent"
                    onClick={() => onClose(true)}
                  >
                    Start your quote
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="product_slider_item_content">
                <h3
                  className="product_slider_title"
                  style={{ marginBottom: 10 }}
                >
                  Window / Door Sensors
                </h3>
                <p className="product_slider_desc">
                  With Nuvogate™ AI, it's smarter exterior protection.
                </p>
                <ul className="nolist">
                  <li className="product_slider_desc">
                    Allow activity tracking
                  </li>
                  <li className="product_slider_desc">
                    Easy with installation
                  </li>
                  <li className="product_slider_desc">Real-time alerts</li>
                </ul>
                <div className="product_slider_item_content_action">
                  <span
                    className="button_white_transparent"
                    onClick={() => onClose(true)}
                  >
                    Start your quote
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="product_slider_item_content">
                <h3
                  className="product_slider_title"
                  style={{ marginBottom: 10 }}
                >
                  Smart Locks
                </h3>
                <p className="product_slider_desc">
                  With Nuvogate™ AI, it's smarter exterior protection.
                </p>
                <ul className="nolist">
                  <li className="product_slider_desc">With keyless entry</li>
                  <li className="product_slider_desc">
                    Voice control compatibility
                  </li>
                  <li className="product_slider_desc">
                    Give remote access into building
                  </li>
                </ul>
                <div className="product_slider_item_content_action">
                  <span
                    className="button_white_transparent"
                    onClick={() => onClose(true)}
                  >
                    Start your quote
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="product_slider_item_content">
                <h3
                  className="product_slider_title"
                  style={{ marginBottom: 10 }}
                >
                  In/outdoor smart lights
                </h3>
                <p className="product_slider_desc">
                  With Nuvogate™ AI, it's smarter exterior protection.
                </p>
                <ul className="nolist">
                  <li className="product_slider_desc">Has a long lifespan</li>
                  <li className="product_slider_desc">
                    Customizable lighting schedules
                  </li>
                  <li className="product_slider_desc">With remote control</li>
                </ul>
                <div className="product_slider_item_content_action">
                  <span
                    className="button_white_transparent"
                    onClick={() => onClose(true)}
                  >
                    Start your quote
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="product_slider_item_content">
                <h3
                  className="product_slider_title"
                  style={{ marginBottom: 10 }}
                >
                  Glass break sensors
                </h3>
                <p className="product_slider_desc">
                  With Nuvogate™ AI, it's smarter exterior protection.
                </p>
                <ul className="nolist">
                  <li className="product_slider_desc">Cover a wide area</li>
                  <li className="product_slider_desc">False alarm reduction</li>
                  <li className="product_slider_desc">Immediate alerts</li>
                </ul>
                <div className="product_slider_item_content_action">
                  <span
                    className="button_white_transparent"
                    onClick={() => onClose(true)}
                  >
                    Start your quote
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
              <div className="product_slider_item_content">
                <h3
                  className="product_slider_title"
                  style={{ marginBottom: 10 }}
                >
                  Gas/water leak detectors
                </h3>
                <p className="product_slider_desc">
                  With Nuvogate™ AI, it's smarter exterior protection.
                </p>
                <ul className="nolist">
                  <li className="product_slider_desc">
                    Prevents property damage
                  </li>
                  <li className="product_slider_desc">
                    Health and safety protection
                  </li>
                  <li className="product_slider_desc">Save cost on repairs</li>
                </ul>
                <div className="product_slider_item_content_action">
                  <span
                    className="button_white_transparent"
                    onClick={() => onClose(true)}
                  >
                    Start your quote
                    <svg
                      width="22"
                      height="21"
                      viewBox="0 0 22 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                </div>
              </div>
            </Slider>
          </div>
        </div>
      </div>

      <QuizModal quiz={quiz} onClose={onClose} key={  quiz ? 'opened' : 'closed'} />
    </section>
  )
}
export default ProductSection
