import React from "react";

const Difference: React.FC = () => {
  return (
    <section
      className="section bg2 product_section"
      style={{ paddingTop: 22, marginTop: -2 }}
    >
      <div className="container container_box">
        <div className="product_table_box">
          <table className="table product_table">
            <thead>
              <tr>
                <th scope="col" style={{ textAlign: "left" }}>
                  <span>
                    The Nuvogate Smart Home<sup>&reg;</sup> difference
                  </span>
                </th>
                <th scope="col table_end">
                  <img src="assets/images/logo-mini.png" alt="logo" />
                </th>
                <th scope="col table_end">Others</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Whole home protection</td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
              </tr>
              <tr>
                <td>Mobile application control & smart home integration</td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
              </tr>
              <tr>
                <td>Great selections of devices</td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
              </tr>
              <tr>
                <td>24/7 professional monitoring</td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
              </tr>
              <tr>
                <td>Constant support and instant response</td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-x-gray.svg" alt="" />
                </td>
              </tr>
              <tr>
                <td>All the data keeped only in your storage</td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-x-gray.svg" alt="" />
                </td>
              </tr>
              <tr>
                <td>No contracts or hidden fees. Cancel anytime</td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-check-green.svg" alt="" />
                </td>
                <td className="table_end">
                  <img src="assets/images/icons/arrow-x-gray.svg" alt="" />
                </td>
              </tr>
            </tbody>
          </table>

          <div className="table_legend">
            *Requires Fast Protect™ Monitoring plan and Smart Alarm™ Indoor
            Wireless Camera.
          </div>
        </div>
      </div>
    </section>
  );
};
export default Difference;
