import React from "react";
import Slider from "react-slick";
import TestimonialItem from "./TestimonialItem";
import { TestimonialItems } from "../constants";

const TestimonialsSlider: React.FC = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  };
  return (
    <Slider {...settings}>
      {TestimonialItems.map((item, index) => {
        return (
          <TestimonialItem
            key={index}
            title={item.title}
            description={item.description}
            date={item.date}
            userName={item.userName}
          />
        );
      })}
    </Slider>
  );
};
export default TestimonialsSlider;
