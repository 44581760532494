import React, { useCallback } from "react"
import Slider from "react-slick"

import { DriveSliderItems } from "../constants"
import SlickItem from "./SlickItem"

type SlickSliderProps = {
  onClick: (packageName?:string) => void
}

const SlickSlider: React.FC<SlickSliderProps> = ({ onClick }) => {

  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
  }

  const onActive = useCallback(
    (packageName: string) => {
      onClick(packageName)
    },
    [onClick]
  )

  return (
    <Slider {...settings}>
      {DriveSliderItems.map((item, index) => {
        return (
          <SlickItem
            key={index}
            title={item.title}
            description={item.description}
            onClick={() => onActive(item.title)}
            icon={item.icon}
            //selected={select === item.index}
          />
        )
      })}
    </Slider>
  )
}
export default SlickSlider
