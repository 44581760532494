import axios from "axios"
import { useCallback } from "react"

const useSendData = () => {
  const sendData = useCallback(
    async ({
      name,
      phone,
      message,
    }: {
      name: string
      phone: string
      message: string
    }) => {
      axios.post(
        "https://us-central1-smart-home-security-beta.cloudfunctions.net/addMessage",
        {
          name,
          phone,
          message,
        }
      )
    },
    []
  )

  return {
    sendData,
  }
}

export default useSendData
