import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useLocation } from "react-router-dom"

import QuizModal from "../modals/QuizModal"

type HeroProps = {
  modal?: boolean
  onModal?: (modal: boolean) => void
}
const Hero: React.FC<HeroProps> = ({ modal, onModal }) => {
  const [quiz, setQuiz] = useState(false)

  const onClose = useCallback((quiz: boolean) => {
    setQuiz(!quiz)
  }, [])

  const onShow = useCallback(
    (quiz: boolean) => {
      if (onModal) {
        onModal(quiz)
        setQuiz(quiz)
      } else {
        onClose(quiz)
      }
      setQuiz(quiz)
    },
    [onClose, onModal]
  )

  const { pathname } = useLocation()

  useEffect(() => {
    if (pathname === "/") {
      window.scrollTo(0, 0)
    }
  }, [pathname])

  const mainPhrase = useMemo(() => {
    const random = Math.random() * 3
    if (random < 1) {
      return (
        <h2>
          Stay Secure
          <br />
          Stay Connected
          <br />
          Smart Home Protection
        </h2>
      )
    } else if (random < 2) {
      return (
        <h2>
          24 / 7 Peace of Mind
          <br />
          Smart Home Security
          <br />
          Discover solutions now!
        </h2>
      )
    }

    return (
      <h2>
        Smart security today
        <br />
        Calm and save future
        <br /> tomorrow!
      </h2>
    )
  }, [])

  const subPhrase = useMemo(() => {
    const random = Math.random() * 3
    if (random < 1) {
      return (
        <p>Get Home Security Now! Secure Your Home with Cutting-Edge Smart Technology</p>
      )
    } else if (random < 2) {
      return (
        <p>Simplify Safety. Take Control of Your Home's Safety with Smart Security Systems!</p>
      )
    }

    return (
      <p>Protect What You Love! Don’t Wait – Secure Your Home with Cutting-Edge Smart Technology!</p>
    )
  }, [])

  const cta = useMemo(() => {
    const random = Math.random() * 4;
    if (random < 1) {
      return "Secure My Home"
    } else if (random < 2) {
      return "Explore now"
    }else if (random < 3) {
      return "Request a Quote"
    }

    return "Join Now"
  }, [])

  return (
    <section className="header_section full_height" id="#">
      <div className="container header_height_correct">
        <div>
          {mainPhrase}
          {subPhrase}
          <div>
            <div
              className="button button_default"
              onClick={() => onClose(quiz)}
            >
              {cta}
            </div>
          </div>
        </div>
      </div>
      <QuizModal quiz={modal === true ? modal : quiz} onClose={onShow} key={modal || quiz ? 'opened' : 'closed'}/>
    </section>
  )
}
export default Hero
