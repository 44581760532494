import React from "react";

type SlickItemProps = {
  title: string;
  icon: any;
  description: string;
  onClick: () => void;
  quiz?: boolean;
  selected?: boolean;
};

const SlickItem: React.FC<SlickItemProps> = ({
  title,
  icon,
  description,
  onClick,
  quiz,
  selected,
}) => {
  return (
    <div className="ss_item" onClick={onClick} style={{ cursor: "pointer" }}>
      <div className="ss_item_header">
        <span>{title}</span>
      </div>
      <div className="ss_item_img">
        <img src={icon} alt="img" />
      </div>
      <div className="ss_item_meta">
        <div className="ss_item_meta_desc">{description}</div>
        <div className="ss_item_meta_action">
          <div>
            {quiz ? (
              selected ? (
                <img src="assets/images/icons/check-success.svg" alt="circle" />
              ) : (
                <img src="assets/images/icons/check-default.svg" alt="circle" />
              )
            ) : selected ? (
              <img src="assets/images/icons/check-success.svg" alt="circle" />
            ) : !quiz && !selected ? (
              <img src="assets/images/icons/arrow-in-circle.svg" alt="circle" />
            ) : (
              <img src="assets/images/icons/check-default.svg" alt="circle" />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
export default SlickItem;
