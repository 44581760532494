import React from "react";
import Hero from "../components/Hero";
import DrivenToProject from "../components/DrivenToProject";
import SecurityOption from "../components/SecurityOption";
import ProductSection from "../components/ProductSection";
import Difference from "../components/Difference";
import ProtectAllBlock from "../components/ProtectAllBlock";
import GetFreeQuote from "../components/GetFreeQuote";
import Testimonials from "../components/Testimonials";

type HomeProps = {
  modal?: boolean;
  onModal?: (modal: boolean) => void;
};

const Home: React.FC<HomeProps> = ({ modal, onModal }) => {
  return (
    <>
      <Hero modal={modal} onModal={onModal} />
      <DrivenToProject />
      <SecurityOption />
      <ProductSection />
      <Difference />
      <ProtectAllBlock />
      <GetFreeQuote />
      <Testimonials />
    </>
  );
};
export default Home;
