import React, { useCallback, useState } from "react"

import { DriveSliderItems } from "../constants"
import QuizModal from "../modals/QuizModal"
import SlickItem from "./SlickItem"
import SlickSlider from "./SlickSlider"

const DrivenToProject: React.FC = () => {
  const [isMobile] = useState(window.innerWidth < 768)
  const [quiz, setQuiz] = useState(false)
  const [packageName, setPackageName] = useState("")

  const onClose = useCallback((quiz: boolean, packageName?: string) => {
    setQuiz(quiz)
    if(packageName){
      setPackageName(packageName);
    }
   
  }, [])

  //const [select, setSelect] = useState(0);

  const onActive = useCallback(
    (index: number) => {
      if (index === 0) {
        setPackageName("Starter Home Pack")
      }
      if (index === 1) {
        setPackageName("Average Smart Pack")
      }
      if (index === 2) {
        setPackageName("Premiere Smart Home Pack")
      }
      onClose(true)
    },
    [onClose]
  )

  return (
    <section className="section bg2" style={{ marginTop: -55 }}>
      <div
        className="section_bg opacity-1"
        style={{ backgroundImage: "url(assets/images/bg-2.webp)" }}
      ></div>

      <div className="container">
        <div className="section_header text-center">
          <h4>Driven to protect</h4>
          <h2>Select a pack leading your fits.</h2>
        </div>
        <div className="system_slider">
          {isMobile ? (
            <SlickSlider
              onClick={(packageName?:string) => onClose(true, packageName)}
              //packageName={packageName}
            />
          ) : (
            <div className="slick-track">
              {DriveSliderItems.map((item, index) => {
                return (
                  <SlickItem
                    key={index}
                    title={item.title}
                    description={item.description}
                    onClick={() => onActive(index)}
                    icon={item.icon}
                    //selected={select === item.index}
                    quiz={false}
                  />
                )
              })}
            </div>
          )}
        </div>
      </div>
      <QuizModal quiz={quiz} onClose={onClose} packageName={packageName} key={ quiz ? 'opened' : 'closed'}/>
    </section>
  )
}
export default DrivenToProject
