export const TestimonialItems = [
  {
    userName: "Elizabeth Ballard",
    date: "Apr 24, 2024",
    title: "Equipment worked as expected and any…",
    description:
      "Equipment worked as expected and any updates were easy to implement. Customer support is great…",
  },
  {
    userName: "DeltaLibra95",
    date: "May 12, 2024",
    title: "Professional",
    description: "Respectful, knowledgeable",
  },
  {
    userName: "Charles R. Nelson",
    date: "Jun 18, 2024",
    title: "Proud to be a customer",
    description:
      "Customer service responds quickly and favorable to my requests. Helpful and great listeners they act…",
  },
  {
    userName: "Rogelio",
    date: "Jun 28, 2024",
    title: "Helping hand",
    description:
      "The help I got with support late at night when I had sensors not working I got help with no hesitation…",
  },
];

export const DriveSliderItems = [
  {
    icon: "assets/images/slide_1.jpg",
    title: "STARTER HOME PACK",
    description:
      "Provides all main smart and security features to protect you home.",
    onClick: () => {},
    index: 0,
  },
  {
    icon: "assets/images/slide_2.jpg",
    title: "AVERAGE SMART PACK",
    description: "Optimal kit of devices to cover all house security lacks.",
    onClick: () => {},
    index: 1,
  },
  {
    icon: "assets/images/slide_3.jpg",
    title: "PREMIERE SMART HOME PACK",
    description:
      "The great set of smart and security tools to make you house a castle",
    onClick: () => {},
    index: 2,
  },
  /*   {
    icon: "assets/images/slide_3.jpg",
    title: "BUILD YOUR OWN SCHEME",
    description: "Your personal choice to balance your needs in home safety",
    onClick: () => {},
    index: 2,
  }, */
];
