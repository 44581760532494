import React from "react";

type ToastProps = {
  text: string;
};

const Toast: React.FC<ToastProps> = ({ text }) => {
  return <div className="toast">{text}</div>;
};
export default Toast;
