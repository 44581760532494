import React, { useCallback, useState } from "react"
import { useNavigate } from "react-router-dom"

import useSendData from "../hooks/useSendData"
import Toast from "./Toast"

type PcFinalFormModalProps = {
  finalize: boolean
  data: any
}

const PcFinalFormModal: React.FC<PcFinalFormModalProps> = ({
  finalize,
  data,
}) => {
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [show, setShow] = useState(finalize)
  const [showFinal, setShowFinal] = useState(false)
  const [toast, setToast] = useState(false)

  const navigate = useNavigate()
  const { sendData } = useSendData()
  const validUserData = name !== "" && phone !== "" && email !== ""

  const onHideSuccess = useCallback(() => {
    setShowFinal(true)
    setShowFinal(false)
  }, [])

  const onSubmit = useCallback(() => {
    if (!validUserData) {
      setToast(true)
    } else {
      setToast(false)

      setShow(false)
      setShowFinal(true)
      setTimeout(onHideSuccess, 3000)

      sendData({
        name,
        phone,
        message: JSON.stringify({
          ...data,
          email,
        }),
      })
    }
  }, [validUserData, data, name, phone, email, onHideSuccess, sendData])

  return (
    <>
      {showFinal ? (
        <div
          className="form_quote_final"
          style={{
            backgroundColor: "#f3f5f4",
            paddingTop: 28,
          }}
        >
          <div className="final_icon">
            <svg
              width="150"
              height="150"
              viewBox="0 0 150 150"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1_4319)">
                <path
                  opacity="0.5"
                  d="M75 0C60.1664 0 45.666 4.39867 33.3323 12.6398C20.9986 20.8809 11.3856 32.5943 5.70907 46.2987C0.0324965 60.0032 -1.45275 75.0832 1.44114 89.6318C4.33503 104.18 11.4781 117.544 21.967 128.033C32.456 138.522 45.8197 145.665 60.3683 148.559C74.9168 151.453 89.9968 149.968 103.701 144.291C117.406 138.614 129.119 129.001 137.36 116.668C145.601 104.334 150 89.8336 150 75C149.979 55.1154 142.07 36.0513 128.009 21.9907C113.949 7.93018 94.8847 0.0215068 75 0ZM75 137.5C62.6387 137.5 50.555 133.834 40.2769 126.967C29.9988 120.099 21.988 110.338 17.2576 98.9177C12.5271 87.4973 11.2894 74.9307 13.701 62.8069C16.1125 50.683 22.0651 39.5466 30.8059 30.8058C39.5466 22.065 50.6831 16.1125 62.8069 13.7009C74.9307 11.2893 87.4974 12.5271 98.9178 17.2575C110.338 21.988 120.099 29.9988 126.967 40.2769C133.834 50.5549 137.5 62.6387 137.5 75C137.482 91.5704 130.891 107.457 119.174 119.174C107.457 130.891 91.5705 137.482 75 137.5Z"
                  fill="#51CC47"
                />
                <path
                  d="M101.831 51.8312L62.5001 91.1625L48.1689 76.8312C46.9901 75.6928 45.4113 75.0628 43.7726 75.077C42.1339 75.0913 40.5663 75.7486 39.4075 76.9074C38.2487 78.0662 37.5914 79.6338 37.5771 81.2725C37.5629 82.9112 38.1929 84.49 39.3314 85.6687L58.0814 104.419C59.2534 105.59 60.8428 106.249 62.5001 106.249C64.1574 106.249 65.7468 105.59 66.9189 104.419L110.669 60.6687C111.807 59.49 112.437 57.9112 112.423 56.2725C112.409 54.6338 111.752 53.0662 110.593 51.9074C109.434 50.7486 107.866 50.0913 106.228 50.077C104.589 50.0628 103.01 50.6928 101.831 51.8312Z"
                  fill="#51CC47"
                />
              </g>
              <defs>
                <clipPath id="clip0_1_4319">
                  <rect width="150" height="150" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </div>

          <div
            className="final_txt"
            style={{ color: "rgba(90, 98, 112, 0.8)" }}
          >
            Your submission has been received!
          </div>
        </div>
      ) : (
        <div
          className={`sec_form_option sec_form_final ${!show ? "hidden" : ""}`}
          style={{position: 'relative'}}
        >
          {toast ? <Toast text="All fields are required!" /> : null}
          <div className="form_inputs">
            <div className="form_col">
              <input
                type="text"
                placeholder="Name"
                required
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>

            <div className="form_col">
              <input
                type="text"
                placeholder="Phone"
                name="sec_opt_phone"
                id="phone"
                required
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </div>

            <div className="form_col">
              <input
                type="email"
                placeholder="Email"
                name="sec_opt_email"
                id="email"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>

          <div className="sec_form_final_bottom">
            <div className="sec_form_final_bottom_left">
              Clicking the button below constitutes your electronic signature
              and consent for Vivint and its parent company NRG to use automated
              technology, including calls, texts and prerecorded messages, to
              contact you at the number provided about Vivint and NRG offers.
              Consent not required to make a purchase. Up to 10msg/month. Reply
              'STOP' to opt-out. See{" "}
              <span
                className="link-finalize"
                onClick={() => navigate("/term-of-service")}
              >
                Terms of Service
              </span>{" "}
              and{" "}
              <span
                className="link-finalize"
                onClick={() => navigate("/privacy")}
              >
                Privacy Policy
              </span>
              .
            </div>
            <div className="sec_form_final_bottom_right">
              <button
                type="button"
                className="button_green_transparent"
                onClick={onSubmit}
              >
                Request A Quote
                <svg
                  width="22"
                  height="21"
                  viewBox="0 0 22 21"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                    fill="#51CC47"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
export default PcFinalFormModal
