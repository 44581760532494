import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Header from "./components/Header";
import Home from "./pages/Home";
import Products from "./pages/Products";
import Calculator from "./pages/Calculator";
import Privacy from "./pages/Privacy";
import Footer from "./components/Footer";
import TermOfService from "./pages/TermOfService";
import { useCallback, useState } from "react";

const Main = () => {
  const [modal, setModal] = useState(false);

  const onModal = useCallback((modal: boolean) => {
    setModal(modal);
  }, []);

  return (
    <Router>
      <Header onModal={onModal} modal={modal} />
      <Routes>
        <Route path="/" element={<Home modal={modal} onModal={onModal} />} />
        <Route path="/calculator" element={<Calculator />} />
        <Route
          path="/privacy"
          element={<Privacy modal={modal} onModal={onModal} />}
        />
        <Route
          path="/term-of-service"
          element={<TermOfService modal={modal} onModal={onModal} />}
        />
      </Routes>
      <Footer />
    </Router>
  );
};

function App() {
  return (
    <div className="App">
      <Main />
    </div>
  );
}

export default App;
