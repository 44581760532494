import React, { useCallback, useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

type HeaderProps = {
  onModal?: (quiz: boolean) => void
  modal: boolean
}

const Header: React.FC<HeaderProps> = ({ onModal, modal }) => {
  const navigate = useNavigate()

  const [isMobile] = useState(window.innerWidth < 768)

  const [scrollPosition, setScrollPosition] = useState(0)

  const handleScroll = () => {
    const position = window.pageYOffset
    setScrollPosition(position)
  }

  useEffect(() => {
    window.addEventListener("scroll", handleScroll, { passive: true })

    return () => {
      window.removeEventListener("scroll", handleScroll)
    }
  }, [])

  const [isOpen, setIsOpen] = useState(false)

  const onOpen = useCallback(() => {
    setIsOpen(!isOpen)
  }, [isOpen])

  const onPath = useCallback(
    (path: string) => {
      navigate(`/${path}`)
      setIsOpen(false)
    },
    [navigate]
  )

  const location = useLocation()
  const { hash } = useLocation()

  const onClose = useCallback(() => {
    if (onModal) {
      onModal(!modal)
    }
  }, [onModal, modal])

  return (
    <header
      style={{
        borderBottom: "1px solid #C0C0C0",
        backdropFilter: !isMobile ? "blur(6px)" : "",
        backgroundColor: isMobile
          ? "rgba(36,37,43,1)"
          : scrollPosition !== 0
          ? "rgba(36,37,43,0.8)"
          : "#24252b",
      }}
    >
      <div className="header_wrapper container">
        <div className="logo">
          <span onClick={() => navigate("/")}>
            <img
              src="assets/images/logo.svg"
              alt="logo"
              style={{ width: 60 }}
            />
          </span>
        </div>
        <nav className="navigation">
          <ul className="main_nav">
            <a className="nav-link" href="#">
              <li
                onClick={() => navigate("/")}
                className={`${
                  location.pathname === "/" && hash !== "#products"
                    ? "current-item"
                    : ""
                }`}
              >
                Home
              </li>
            </a>
            <a className="nav-link" href="#products">
              <li
                className={`${hash === "#products" ? "current-item" : ""}`}
                onClick={() => navigate("/")}
              >
                Products
              </li>
            </a>
            <li
              className={`${
                location.pathname === "/calculator" ? "current-item" : ""
              }`}
            >
              <span className="nav-link" onClick={onClose}>
                Calculator
              </span>
            </li>
          </ul>
        </nav>
        <div className="action">
          <span
            className="btn btn-action quiz-action"
            data-target="form_start_quiz"
            onClick={onClose}
          >
            Start Security Today
            <img src="assets/images/icons/arrow forward.svg" alt="start" />
          </span>
        </div>

        <div className="mob mob_action">
          <span className="mob_btn_open" onClick={onOpen}>
            {isOpen ? (
              <img src="assets/images/icons/close.svg" alt="close" />
            ) : (
              <img src="assets/images/icons/menu.svg" alt="menu" />
            )}
          </span>
        </div>

        <div
          className="mob mob_nav_wrapper"
          style={{ right: isOpen ? 0 : "-120vw" }}
        >
          <nav className="navigation">
            <ul className="main_nav">
              <span
                onClick={() => {
                  onPath("")
                }}
              >
                <a href="#"> Home</a>
              </span>
              <span
                onClick={() => {
                  onPath("")
                }}
              >
                <a href="#mobile_product">Products</a>
              </span>
              {isMobile ? (
                <li
                  style={{ background: "transparent", color: "#51CC47" }}
                  onClick={onClose}
                >
                  Get a Free Quote
                  <img
                    style={{ paddingLeft: 5, marginTop: 3, fill: "#fff" }}
                    src="assets/images/icons/arrow forward.svg"
                    alt="forward"
                  />
                </li>
              ) : null}
            </ul>
          </nav>
          {!isMobile ? (
            <div className="action">
              <span className="btn btn-action" onClick={onClose}>
              Get a Free Quote
                <img
                  style={{ paddingLeft: 5, marginTop: 3 }}
                  src="assets/images/icons/arrow forward.svg"
                  alt="forward"
                />
              </span>
            </div>
          ) : null}

          <div className="mob_nav_footer">
            <ul>
              <li>
                <span
                  className="mobile-footer-link"
                  onClick={() => onPath("privacy")}
                >
                  Privacy Policy
                </span>
              </li>
              <li>
                <span
                  className="mobile-footer-link"
                  onClick={() => onPath("term-of-service")}
                >
                  Term of Service
                </span>
              </li>
            </ul>
            <div className="mob_nav_footer_copy">
              <p>
                Copyright © 2024 Nuvogate Smart Home, Inc. All rights reserved.
              </p>
              <p>
                Do not sell or share my personal information. Limit the use of
                my sensitive personal information.
              </p>
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
