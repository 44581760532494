import React from "react";
import { useNavigate } from "react-router-dom";

const Footer: React.FC = () => {
  const navigate = useNavigate();
  return (
    <footer style={{ marginTop: -1, marginBottom: -2 }}>
      <div className="container">
        <div className="footer_top">
          <div className="footer_logo" onClick={() => navigate("/")}>
            <img src="assets/images/flogo.png" alt="logo" />
          </div>
          <div className="footer_links">
            <ul className="footer_nav">
              <li>
                <span
                  className="policy-link"
                  onClick={() => navigate("/privacy")}
                >
                  Privacy Policy
                </span>
              </li>
              <li>
                <span
                  onClick={() => navigate("/term-of-service")}
                  className="policy-link"
                >
                  Term of Service
                </span>
              </li>
            </ul>
          </div>
        </div>
        <div className="footer_copy">
          <p>
            Copyright &copy; 2024 Nuvogate Smart Home, Inc. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};
export default Footer;
