import React, { useCallback, useMemo, useState } from "react"
import { useNavigate } from "react-router-dom"

import useSendData from "../hooks/useSendData"
import PcFinalFormModal from "../modals/PcFinalFormModal"
import { useOutsideClick } from "../modals/QuizModal"
import Toast from "../modals/Toast"

const SecurityOption: React.FC = () => {
  const [isMobile] = useState(window.innerWidth < 768)

  const navigate = useNavigate()
  const { sendData } = useSendData()

  const [firstOptionsShow, setFirstOptionsShow] = useState(
    isMobile ? true : false
  )
  const [secondOptionsShow, setSecondOptionsShow] = useState(false)
  const [thirdOptionsShow, setThirdOptionsShow] = useState(false)
  const [finalize, setFinalize] = useState(false)
  const [interestedIn, setInterestedIn] = useState("")
  const [residenceType, setResidenceType] = useState("")
  const [doors, setDoors] = useState("")
  const [name, setName] = useState("")
  const [phone, setPhone] = useState("")
  const [email, setEmail] = useState("")
  const [checkHome, setCheckHome] = useState(false)
  const [checkHomeSecurity, setCheckHomeSecurity] = useState(false)
  const [checkBoth, setCheckBoth] = useState(false)
  const [checkSingle, setCheckSingle] = useState(false)
  const [checkTownhome, setCheckTownhome] = useState(false)
  const [checkCondo, setCheckCondo] = useState(false)
  const [checkApartment, setCheckApartment] = useState(false)
  const [checkTrailer, setCheckTrailer] = useState(false)
  const [checkOther, setCheckOther] = useState(false)
  const [checkWindow, setCheckWindow] = useState(false)
  const [checkAutomation, setCheckAutomation] = useState(false)
  const [checkBothSecurity, setCheckBothSecurity] = useState(false)
  const [requestBlock, setRequestBlock] = useState(false)
  const [validInterest, setValidInterest] = useState(false)
  const [validResidenceType, setValidResidenceType] = useState(false)
  const [validDoors, setValidDoors] = useState(false)
  const [toast, setToast] = useState(false)
  const [showFinal, setShowFinal] = useState(true)

  const validInterestedIn = checkHomeSecurity || checkHome || checkBoth
  const validResidence =
    checkSingle ||
    checkTownhome ||
    checkCondo ||
    checkApartment ||
    checkTrailer ||
    checkOther

  const validDoorsWindows = checkWindow || checkAutomation || checkBothSecurity

  const validUserData = name !== "" && phone !== "" && email !== ""

  const onFirstOptionsShow = useCallback(() => {
    setSecondOptionsShow(false)
    setThirdOptionsShow(false)
    setFirstOptionsShow(!firstOptionsShow)
    setFinalize(false)
    setValidResidenceType(false)
  }, [firstOptionsShow])

  const onFirstOptionsShowBack = useCallback(() => {
    setSecondOptionsShow(false)
    setThirdOptionsShow(false)
    setFinalize(false)
    setFirstOptionsShow(true)
    setValidResidenceType(false)
  }, [])

  const onSecondOptionsShowBack = useCallback(() => {
    setValidDoors(false)
    setSecondOptionsShow(true)
    setThirdOptionsShow(false)
    setFinalize(false)
    setFirstOptionsShow(false)
  }, [])

  const onThirdOptionsShowBack = useCallback(() => {
    setSecondOptionsShow(false)
    setThirdOptionsShow(true)
    setFirstOptionsShow(false)
    setFinalize(false)
  }, [])

  const onFirstOptionsShowMobile = useCallback(() => {
    if (!validInterestedIn) {
      setValidInterest(true)
      setSecondOptionsShow(false)
      setFirstOptionsShow(true)
    } else {
      setValidInterest(false)
      setSecondOptionsShow(true)
      setThirdOptionsShow(false)
      setFirstOptionsShow(false)
      setFinalize(false)
    }
  }, [validInterestedIn])

  const onSecondOptionsShowMobile = useCallback(() => {
    if (!validResidence) {
      setValidResidenceType(true)
      setFinalize(false)
      setSecondOptionsShow(true)
    } else {
      setSecondOptionsShow(false)
      setThirdOptionsShow(true)
      setFirstOptionsShow(false)
      setFinalize(false)
      setValidResidenceType(false)
    }
  }, [validResidence])

  const onSecondOptionsShow = useCallback(() => {
    if (!validInterestedIn) {
      setValidInterest(true)
      setSecondOptionsShow(false)
      setFirstOptionsShow(true)
    } else {
      setSecondOptionsShow(!secondOptionsShow)
      setFirstOptionsShow(false)
      setThirdOptionsShow(false)
      setFinalize(false)
      setValidInterest(false)
    }
  }, [secondOptionsShow, validInterestedIn])

  const onThirdOptionsShow = useCallback(() => {
    if (!validResidence) {
      setValidResidenceType(true)
      setFinalize(false)
      setSecondOptionsShow(true)
    } else {
      setThirdOptionsShow(!thirdOptionsShow)
      setFirstOptionsShow(false)
      setSecondOptionsShow(false)
      setFinalize(false)
      setValidResidenceType(false)
    }
  }, [thirdOptionsShow, validResidence])

  const onFinalize = useCallback(() => {
    if (!validDoorsWindows) {
      setValidDoors(true)
      setThirdOptionsShow(true)
    } else {
      setValidDoors(false)
      setFinalize(!finalize)
      setThirdOptionsShow(false)
      setFirstOptionsShow(false)
      setSecondOptionsShow(false)
    }
  }, [finalize, validDoorsWindows])

  const data = useMemo(() => {
    return {
      interestedIn: interestedIn,
      residenceType: residenceType,
      doors: doors,
    }
  }, [doors, interestedIn, residenceType])

  const onInterestIn = useCallback(
    (value: string) => {
      if (value === "Home Security") {
        setCheckHome(!checkHome)
        setCheckHomeSecurity(false)
        setCheckBoth(false)
        setInterestedIn(value)
        setValidInterest(false)
        if (checkHome) {
          setInterestedIn("")
        }
      }
      if (value === "Smart Home Automation") {
        setCheckHomeSecurity(!checkHomeSecurity)
        setCheckHome(false)
        setCheckBoth(false)
        setInterestedIn(value)
        setValidInterest(false)
        if (checkHomeSecurity) {
          setInterestedIn("")
        }
      }
      if (value === "Both") {
        setCheckBoth(!checkBoth)
        setCheckHomeSecurity(false)
        setCheckHome(false)
        setValidInterest(false)
        setInterestedIn(value)
        if (checkBoth) {
          setInterestedIn("")
        }
      }
    },
    [checkHome, checkHomeSecurity, checkBoth]
  )

  const onResidenceType = useCallback(
    (value: string) => {
      if (value === "Single Family") {
        setCheckSingle(!checkSingle)
        setCheckTownhome(false)
        setCheckCondo(false)
        setCheckApartment(false)
        setCheckTrailer(false)
        setCheckOther(false)
        setValidResidenceType(false)
        setResidenceType(value)
        if (checkSingle) {
          setResidenceType("")
        }
      }
      if (value === "Townhome") {
        setCheckTownhome(!checkTownhome)
        setCheckSingle(false)
        setCheckCondo(false)
        setCheckApartment(false)
        setCheckTrailer(false)
        setCheckOther(false)
        setResidenceType(value)
        setValidResidenceType(false)
        if (checkTownhome) {
          setResidenceType("")
        }
      }
      if (value === "Condo") {
        setCheckCondo(!checkCondo)
        setCheckTownhome(false)
        setCheckSingle(false)
        setCheckApartment(false)
        setCheckTrailer(false)
        setCheckOther(false)
        setResidenceType(value)
        setValidResidenceType(false)
        if (checkCondo) {
          setResidenceType("")
        }
      }
      if (value === "Apartment") {
        setCheckApartment(!checkApartment)
        setCheckCondo(false)
        setCheckTownhome(false)
        setCheckSingle(false)
        setCheckTrailer(false)
        setCheckOther(false)
        setResidenceType(value)
        setValidResidenceType(false)
        if (checkApartment) {
          setResidenceType("")
        }
      }
      if (value === "Trailer") {
        setCheckTrailer(!checkTrailer)
        setCheckApartment(false)
        setCheckCondo(false)
        setCheckTownhome(false)
        setCheckSingle(false)
        setCheckHomeSecurity(false)
        setCheckHome(false)
        setCheckOther(false)
        setResidenceType(value)
        setValidResidenceType(false)
        if (checkTrailer) {
          setResidenceType("")
        }
      }
      if (value === "Other") {
        setCheckOther(!checkOther)
        setCheckTrailer(false)
        setCheckApartment(false)
        setCheckCondo(false)
        setCheckTownhome(false)
        setCheckSingle(false)
        setCheckHomeSecurity(false)
        setCheckHome(false)
        setResidenceType(value)
        setValidResidenceType(false)
        if (checkOther) {
          setResidenceType("")
        }
      }
    },
    [
      checkSingle,
      checkTownhome,
      checkCondo,
      checkApartment,
      checkTrailer,
      checkOther,
    ]
  )
  const onDoors = useCallback(
    (value: string) => {
      if (value === "Window Home Security") {
        setCheckWindow(!checkWindow)
        setCheckAutomation(false)
        setCheckBothSecurity(false)
        setDoors(value)
        setValidDoors(false)
        if (checkWindow) {
          setDoors("")
        }
      }
      if (value === "Smart Door Automation") {
        setCheckAutomation(!checkAutomation)
        setCheckWindow(false)
        setCheckBothSecurity(false)
        setDoors(value)
        setValidDoors(false)
        if (checkAutomation) {
          setDoors("")
        }
      }
      if (value === "Window & Door Security") {
        setCheckBothSecurity(!checkBothSecurity)
        setCheckWindow(false)
        setCheckAutomation(false)
        setDoors(value)
        setValidDoors(false)
        if (checkBothSecurity) {
          setDoors("")
        }
      }
    },
    [checkWindow, checkAutomation, checkBothSecurity]
  )
  const onHideSuccess = useCallback(() => {
    setRequestBlock(false)
  }, [])

  const onMobileSubmit = useCallback(() => {
    if (!validUserData) {
      setToast(true)
    } else {
      setToast(false)
      setShowFinal(false)
      setCheckBothSecurity(false)
      setCheckWindow(false)
      setCheckAutomation(false)
      setRequestBlock(true)
      setTimeout(onHideSuccess, 3000)
      setFinalize(false)
    }

    sendData({
      name,
      phone,
      message: JSON.stringify({
        ...data,
        email,
      }),
    })
  }, [validUserData, data, name, phone, email, sendData, onHideSuccess])

  const ref = useOutsideClick(() => {
    if (!isMobile) {
      setFirstOptionsShow(false)
      setValidInterest(false)
      setSecondOptionsShow(false)
      setValidResidenceType(false)
      setThirdOptionsShow(false)
      setValidDoors(false)
    }
  })

  return (
    <section className="section bg_green cta-section" style={{ marginTop: -1 }}>
      <div className="container">
        <div className="cta_box">
          <h2>
            Let us define your home shield. Choose needed security and smart
            options.
          </h2>

          {/*   <!-- Pc version --> */}
          <div className="cta_form" id="products">
            {finalize ? (
              <PcFinalFormModal finalize={finalize} data={data} />
            ) : (
              <>
                <div className="sec_options_box">
                  <div
                    className="div_sec_option"
                    style={{
                      borderRight:
                        firstOptionsShow || secondOptionsShow
                          ? "none"
                          : "solid 1px #bfc7c7",
                    }}
                  >
                    <button
                      onClick={onFirstOptionsShow}
                      className={`${
                        firstOptionsShow
                          ? "btn_security_option_active btn_sec_option"
                          : "btn_sec_option"
                      }`}
                    >
                      <span>Interested In</span>
                      <span>{interestedIn ? interestedIn : "Choose one"}</span>
                    </button>
                  </div>
                  <div
                    className="div_sec_option"
                    style={{
                      borderRight:
                        secondOptionsShow || thirdOptionsShow
                          ? "none"
                          : "solid 1px #bfc7c7",
                    }}
                  >
                    <button
                      onClick={onSecondOptionsShow}
                      className={`${
                        secondOptionsShow
                          ? "btn_security_option_active btn_sec_option"
                          : "btn_sec_option"
                      }`}
                    >
                      <span>Residence Type</span>
                      <span>
                        {residenceType ? residenceType : "Choose one"}
                      </span>
                    </button>
                  </div>
                  <div
                    className="div_sec_option"
                    style={{
                      borderRight: "none",
                    }}
                  >
                    <button
                      className={`${
                        thirdOptionsShow
                          ? "btn_security_option_active btn_sec_option"
                          : "btn_sec_option"
                      }`}
                      onClick={onThirdOptionsShow}
                    >
                      <span>Doors & Windows</span>
                      <span>{doors ? doors : "Choose any"}</span>
                    </button>
                  </div>
                  <button
                    className="btn_sec_option_finalize"
                    onClick={onFinalize}
                    disabled={
                      !validDoorsWindows &&
                      !validInterestedIn &&
                      !validResidence
                    }
                  >
                    <span>Finalize</span>
                    <svg
                      width="21"
                      height="21"
                      viewBox="0 0 21 21"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M3.50586 11.3828H14.1289L9.22754 16.2842L10.499 17.4941L17.4922 10.501L10.499 3.50781L9.26855 4.71777L14.1289 9.61914H3.50586V11.3828Z"
                        fill="white"
                      />
                    </svg>
                  </button>
                </div>

                {/*   <!-- Option 1 --> */}
                <div ref={ref}>
                  {validInterest ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 310,
                        bottom: "-2%",
                        left: -60,
                      }}
                    >
                      <Toast text="Please select one!" />
                    </div>
                  ) : null}
                  <div
                    className={`sec_form_option ${
                      !firstOptionsShow ? "hidden" : ""
                    }`}
                  >
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_1a"
                        onChange={() => setInterestedIn("Home Security")}
                        onClick={() => onInterestIn("Home Security")}
                        checked={checkHome}
                      />
                      <label htmlFor="sec_opt_1a">Home Security</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_1b"
                        onChange={() =>
                          setInterestedIn("Smart Home Automation")
                        }
                        onClick={() => onInterestIn("Smart Home Automation")}
                        checked={checkHomeSecurity}
                      />
                      <label htmlFor="sec_opt_1b">Smart Home Automation</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_1c"
                        onChange={() =>
                          setInterestedIn("Security & Automation")
                        }
                        onClick={() => onInterestIn("Both")}
                        checked={checkBoth}
                      />
                      <label htmlFor="sec_opt_1c">Both</label>
                    </p>
                  </div>
                </div>

                {/*  <!-- Option 2 --> */}
                <div ref={ref}>
                  {validResidenceType ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 310,
                        bottom: "-2%",
                        left: 140,
                      }}
                    >
                      <Toast text="Please select one!" />
                    </div>
                  ) : null}
                  <div
                    className={`sec_form_option ${
                      !secondOptionsShow ? "hidden" : ""
                    }`}
                    style={{ marginLeft: "25%" }}
                  >
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_2a"
                        onClick={() => onResidenceType("Single Family")}
                        onChange={() => setResidenceType("Single Family")}
                        checked={checkSingle}
                      />
                      <label htmlFor="sec_opt_2a">Single Family</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_2b"
                        onClick={() => onResidenceType("Townhome")}
                        onChange={() => setResidenceType("Townhome")}
                        checked={checkTownhome}
                      />
                      <label htmlFor="sec_opt_2b">Townhome</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_2c"
                        onClick={() => onResidenceType("Condo")}
                        onChange={() => setResidenceType("Condo")}
                        checked={checkCondo}
                      />
                      <label htmlFor="sec_opt_2c">Condo</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_2d"
                        onClick={() => onResidenceType("Apartment")}
                        onChange={() => setResidenceType("Apartment")}
                        checked={checkApartment}
                      />
                      <label htmlFor="sec_opt_2d">Apartment</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_2e"
                        onClick={() => onResidenceType("Trailer")}
                        onChange={() => setResidenceType("Trailer")}
                        checked={checkTrailer}
                      />
                      <label htmlFor="sec_opt_2e">Trailer</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_2f"
                        onClick={() => onResidenceType("Other")}
                        onChange={() => setResidenceType("Other")}
                        checked={checkOther}
                      />
                      <label htmlFor="sec_opt_2f">Other</label>
                    </p>
                  </div>
                </div>
                <div ref={ref}>
                  {validDoors ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 310,
                        bottom: "-2%",
                        left: 420,
                      }}
                    >
                      <Toast text="Please select one!" />
                    </div>
                  ) : null}
                  <div
                    className={`sec_form_option ${
                      !thirdOptionsShow ? "hidden" : ""
                    }`}
                    style={{ marginLeft: "50%" }}
                  >
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_3a"
                        onClick={() => onDoors("Window Home Security")}
                        onChange={() => setDoors("Window Home Security")}
                        checked={checkWindow}
                      />
                      <label htmlFor="sec_opt_3a">Window Home Security</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_3b"
                        onClick={() => onDoors("Smart Door Automation")}
                        onChange={() => setDoors("Smart Door Automation")}
                        checked={checkAutomation}
                      />
                      <label htmlFor="sec_opt_3b">Smart Door Automation</label>
                    </p>
                    <p>
                      <input
                        type="radio"
                        id="sec_opt_3c"
                        onClick={() => onDoors("Window & Door Security")}
                        onChange={() => setDoors("Window & Door Security")}
                        checked={checkBothSecurity}
                      />
                      <label htmlFor="sec_opt_3c">Both</label>
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>

          {/*   <!-- Mob version --> */}
          {isMobile ? (
            requestBlock ? (
              <div
                className="form_quote_final"
                style={{
                  backgroundColor: "#f3f5f4",
                  paddingTop: 28,
                }}
              >
                <div className="final_icon">
                  <svg
                    width="150"
                    height="150"
                    viewBox="0 0 150 150"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_1_4319)">
                      <path
                        opacity="0.5"
                        d="M75 0C60.1664 0 45.666 4.39867 33.3323 12.6398C20.9986 20.8809 11.3856 32.5943 5.70907 46.2987C0.0324965 60.0032 -1.45275 75.0832 1.44114 89.6318C4.33503 104.18 11.4781 117.544 21.967 128.033C32.456 138.522 45.8197 145.665 60.3683 148.559C74.9168 151.453 89.9968 149.968 103.701 144.291C117.406 138.614 129.119 129.001 137.36 116.668C145.601 104.334 150 89.8336 150 75C149.979 55.1154 142.07 36.0513 128.009 21.9907C113.949 7.93018 94.8847 0.0215068 75 0ZM75 137.5C62.6387 137.5 50.555 133.834 40.2769 126.967C29.9988 120.099 21.988 110.338 17.2576 98.9177C12.5271 87.4973 11.2894 74.9307 13.701 62.8069C16.1125 50.683 22.0651 39.5466 30.8059 30.8058C39.5466 22.065 50.6831 16.1125 62.8069 13.7009C74.9307 11.2893 87.4974 12.5271 98.9178 17.2575C110.338 21.988 120.099 29.9988 126.967 40.2769C133.834 50.5549 137.5 62.6387 137.5 75C137.482 91.5704 130.891 107.457 119.174 119.174C107.457 130.891 91.5705 137.482 75 137.5Z"
                        fill="#51CC47"
                      />
                      <path
                        d="M101.831 51.8312L62.5001 91.1625L48.1689 76.8312C46.9901 75.6928 45.4113 75.0628 43.7726 75.077C42.1339 75.0913 40.5663 75.7486 39.4075 76.9074C38.2487 78.0662 37.5914 79.6338 37.5771 81.2725C37.5629 82.9112 38.1929 84.49 39.3314 85.6687L58.0814 104.419C59.2534 105.59 60.8428 106.249 62.5001 106.249C64.1574 106.249 65.7468 105.59 66.9189 104.419L110.669 60.6687C111.807 59.49 112.437 57.9112 112.423 56.2725C112.409 54.6338 111.752 53.0662 110.593 51.9074C109.434 50.7486 107.866 50.0913 106.228 50.077C104.589 50.0628 103.01 50.6928 101.831 51.8312Z"
                        fill="#51CC47"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_1_4319">
                        <rect width="150" height="150" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </div>

                <div
                  className="final_txt"
                  style={{ color: "rgba(90, 98, 112, 0.8)" }}
                >
                  Your submission has been received!
                </div>
              </div>
            ) : showFinal ? (
              <div className="cta_form_mob mob-only">
                {/*  <!-- Mob box 1 --> */}
                <div>
                  {validInterest ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 310,
                        bottom: "60%",
                        left: 30,
                      }}
                    >
                      <Toast text="Please select one!" />
                    </div>
                  ) : null}
                  <div
                    className="sec_options_box_1"
                    style={{ display: firstOptionsShow ? "block" : "none" }}
                  >
                    <div className="sec_options_box_mob_title">
                      <span>Interested In</span>
                      <span>"Choose one"</span>
                    </div>

                    <div className="sec_options_box_fields v1 radio">
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_1a"
                          onChange={() => setInterestedIn("Home Security")}
                          onClick={() => onInterestIn("Home Security")}
                          checked={checkHome}
                        />
                        <label htmlFor="sec_opt_1a">Home Security</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_1b"
                          onChange={() =>
                            setInterestedIn("Smart Home Automation")
                          }
                          onClick={() => onInterestIn("Smart Home Automation")}
                          checked={checkHomeSecurity}
                        />
                        <label htmlFor="sec_opt_1b">
                          Smart Home Automation
                        </label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_1c"
                          onChange={() =>
                            setInterestedIn("Security & Automation")
                          }
                          onClick={() => onInterestIn("Both")}
                          checked={checkBoth}
                        />
                        <label htmlFor="sec_opt_1c">Both</label>
                      </p>
                    </div>

                    <div className="sec_options_box_footer">
                      <div className="mob_process">
                        <span className="dot1"></span>
                        <span className="dot2"></span>
                        <span className="dot3"></span>
                      </div>

                      <div
                        className={`${
                          !interestedIn
                            ? "step_next step_next_disabled"
                            : "step_next"
                        } `}
                        onClick={onFirstOptionsShowMobile}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          viewBox="0 0 448 512"
                          fill={!interestedIn ? "#ccc" : "#51cc47"}
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>

                {/*   <!-- Mob box 2 --> */}
                <>
                  {validResidenceType ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 310,
                        bottom: "68%",
                        left: 35,
                      }}
                    >
                      <Toast text="Please select one!" />
                    </div>
                  ) : null}
                  <div
                    className={`sec_options_box_2 ${
                      !secondOptionsShow ? "hidden" : ""
                    }`}
                  >
                    <div className="sec_options_box_mob_title">
                      <span>Residence Type</span>
                      <span>Choose one</span>
                    </div>

                    <div className="sec_options_box_fields v2 radio">
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_2a"
                          onClick={() => onResidenceType("Single Family")}
                          onChange={() => setResidenceType("Single Family")}
                          checked={checkSingle}
                        />
                        <label htmlFor="sec_opt_2a">Single Family</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_2b"
                          onClick={() => onResidenceType("Townhome")}
                          onChange={() => setResidenceType("Townhome")}
                          checked={checkTownhome}
                        />
                        <label htmlFor="sec_opt_2b">Townhome</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_2c"
                          onClick={() => onResidenceType("Condo")}
                          onChange={() => setResidenceType("Condo")}
                          checked={checkCondo}
                        />
                        <label htmlFor="sec_opt_2c">Condo</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_2d"
                          onClick={() => onResidenceType("Apartment")}
                          onChange={() => setResidenceType("Apartment")}
                          checked={checkApartment}
                        />
                        <label htmlFor="sec_opt_2d">Apartment</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_2e"
                          onClick={() => onResidenceType("Trailer")}
                          onChange={() => setResidenceType("Trailer")}
                          checked={checkTrailer}
                        />
                        <label htmlFor="sec_opt_2e">Trailer</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_2f"
                          onClick={() => onResidenceType("Other")}
                          onChange={() => setResidenceType("Other")}
                          checked={checkOther}
                        />
                        <label htmlFor="sec_opt_2f">Other</label>
                      </p>
                    </div>

                    <div className="sec_options_box_footer">
                      <div
                        className="step_back"
                        onClick={onFirstOptionsShowBack}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          viewBox="0 0 448 512"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z"></path>
                        </svg>
                      </div>

                      <div className="mob_process">
                        <span className="dot1"></span>
                        <span className="dot2"></span>
                        <span className="dot3"></span>
                      </div>

                      <div
                        className={`${
                          !validResidence
                            ? "step_next step_next_disabled"
                            : "step_next"
                        } `}
                        onClick={onSecondOptionsShowMobile}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          viewBox="0 0 448 512"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </>

                {/*  <!-- Mob box 3 --> */}
                <>
                  {validDoors ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 310,
                        bottom: "60%",
                        left: 35,
                      }}
                    >
                      <Toast text="Please select one!" />
                    </div>
                  ) : null}
                  <div
                    className={`sec_options_box_3 ${
                      !thirdOptionsShow ? "hidden" : ""
                    }`}
                  >
                    <div className="sec_options_box_mob_title">
                      <span>Doors & Windows</span>
                      <span>Choose one</span>
                    </div>

                    <div className="sec_options_box_fields v3 radio">
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_3a"
                          onClick={() => onDoors("Window Home Security")}
                          onChange={() => setDoors("Window Home Security")}
                          checked={checkWindow}
                        />
                        <label htmlFor="sec_opt_3a">Window Home Security</label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_3b"
                          onClick={() => onDoors("Smart Door Automation")}
                          onChange={() => setDoors("Smart Door Automation")}
                          checked={checkAutomation}
                        />
                        <label htmlFor="sec_opt_3b">
                          Smart Door Automation
                        </label>
                      </p>
                      <p>
                        <input
                          type="radio"
                          id="sec_opt_3c"
                          onClick={() => onDoors("Window & Door Security")}
                          onChange={() => setDoors("Window & Door Security")}
                          checked={checkBothSecurity}
                        />
                        <label htmlFor="sec_opt_3c">Both</label>
                      </p>
                    </div>

                    <div className="sec_options_box_footer">
                      <div
                        className="step_back"
                        onClick={onSecondOptionsShowBack}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          viewBox="0 0 448 512"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                      </div>

                      <div className="mob_process">
                        <span className="dot1"></span>
                        <span className="dot2"></span>
                        <span className="dot3"></span>
                      </div>

                      <div
                        className={`${
                          !validDoorsWindows
                            ? "step_next step_next_disabled"
                            : "step_next"
                        } `}
                        onClick={onFinalize}
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="30"
                          viewBox="0 0 448 512"
                        >
                          <path d="M9.4 233.4c-12.5 12.5-12.5 32.8 0 45.3l160 160c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L109.2 288 416 288c17.7 0 32-14.3 32-32s-14.3-32-32-32l-306.7 0L214.6 118.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0l-160 160z" />
                        </svg>
                      </div>
                    </div>
                  </div>
                </>

                {/*  <!-- Mob box 4 --> */}
                <>
                  {toast ? (
                    <div
                      style={{
                        position: "absolute",
                        width: 310,
                        bottom: "74%",
                        left: 25,
                      }}
                    >
                      <Toast text="All fields are required!" />
                    </div>
                  ) : null}
                  <div
                    className={`sec_options_box_4 ${!finalize ? "hidden" : ""}`}
                  >
                    <div className="sec_options_box_mob_title">
                      <span></span>
                      <span>Finalize</span>
                    </div>

                    <div className="form_inputs">
                      <div className="form_col">
                        <input
                          type="text"
                          placeholder="Name"
                          required
                          onChange={(e) => setName(e.target.value)}
                          value={name}
                          className="reg"
                        />
                      </div>

                      <div className="form_col">
                        <input
                          type="text"
                          placeholder="Phone"
                          name="sec_opt_phone"
                          id="phone"
                          required
                          value={phone}
                          onChange={(e) => setPhone(e.target.value)}
                          className="reg"
                        />
                      </div>

                      <div className="form_col">
                        <input
                          type="email"
                          placeholder="Email"
                          name="sec_opt_email"
                          id="email"
                          required
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          className="reg"
                        />
                      </div>
                    </div>

                    <div className="sec_options_box_mob_txt">
                      Clicking the button below constitutes your electronic
                      signature and consent for Vivint and its parent company
                      NRG to use automated technology, including calls, texts
                      and prerecorded messages, to contact you at the number
                      provided about Vivint and NRG offers. Consent not required
                      to make a purchase. Up to 10msg/month. Reply 'STOP' to
                      opt-out. See{" "}
                      <span
                        className="link"
                        onClick={() => navigate("/privacy")}
                      >
                        Terms of Service
                      </span>{" "}
                      and{" "}
                      <span
                        className="link"
                        onClick={() => navigate("/term-of-service")}
                      >
                        Privacy Policy
                      </span>
                      .
                    </div>

                    <div className="sec_options_box_mob_action">
                      <button
                        type="button"
                        className="button_green_transparent"
                        onClick={onMobileSubmit}
                      >
                        Request A Quote
                        <svg
                          width="22"
                          height="21"
                          viewBox="0 0 22 21"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M3.63867 11.3809H14.2617L9.36035 16.2822L10.6318 17.4922L17.625 10.499L10.6318 3.50586L9.40137 4.71582L14.2617 9.61719H3.63867V11.3809Z"
                            fill="#51CC47"
                          />
                        </svg>
                      </button>
                    </div>

                   
                  </div>
                </>
              </div>
            ) : null
          ) : null}
        </div>
      </div>
    </section>
  )
}
export default SecurityOption
