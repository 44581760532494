import React from "react";

type TestimonialItemProps = {
  userName: string;
  title: string;
  date: string;
  description: string;
};

const TestimonialItem: React.FC<TestimonialItemProps> = ({
  userName,
  title,
  date,
  description,
}) => {
  return (
    <div className="testimonial_slider_item">
      <div>
        <div className="testimonial_stars">
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className="testimonial_name">{userName}</div>
        <div className="testimonial_date">{date}</div>
        <div className="testimonial_title">{title}</div>
        <div className="testimonial_content">{description}</div>
      </div>
    </div>
  );
};
export default TestimonialItem;
